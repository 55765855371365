<template>
  <div>
    <v-sheet elevation="1" class="mb-2 mx-1 my-2">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text icon @click="getData(true)">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
        <v-btn text icon @click="add()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table :loading="loading" :items="data_list" :headers="data_list_columns" dense>
        <template v-slot:[`item.description`]="{ item }">
          <div style="max-width: 600px; white-space: normal">
            <div style="white-space: break-spaces">{{ item.description }}</div>
          </div>
        </template>
        <template v-slot:[`item.attachment`]="{ item }">
          <v-btn :href="item.attachment" target="_blank" icon text x-small v-if="item.attachment !== '' && item.attachment !== 'null'" title="Clict to download">
            <v-icon>mdi-attachment</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn x-small icon text class="mx-1" @click="editRow(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn x-small icon text class="mx-1" @click="deleteRow(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-sheet>

    <v-dialog max-width="600" v-model="fmAdd.dialog">
      <v-card>
        <v-card-title>Add New</v-card-title>
        <v-card-text>
          <v-autocomplete :items="line_nos" dense hide-details v-model="fmAdd.data.line_no_" outlined class="mb-2" title="Line No_" placeholder="Line No_"></v-autocomplete>
          <v-textarea dense hide-details v-model="fmAdd.data.description" outlined class="mb-2" title="Description" placeholder="Description"></v-textarea>
          <v-text-field append-icon="mdi-upload" @click:append="uploadFile()" dense v-model="fmAdd.data.attachment" placeholder="Attachment" label="Attachment" outlined hide-details class="mb-2"></v-text-field>
          <v-text-field type="date" dense hide-details v-model="fmAdd.data.action_date" outlined class="mb-2" title="Tanggal" placeholder="Tanggal"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveData()">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="fmAdd.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="600" v-model="fmEdit.dialog">
      <v-card>
        <v-card-title>Modify</v-card-title>
        <v-card-text>
          <v-autocomplete :items="line_nos" dense hide-details v-model="fmEdit.data.line_no_" outlined class="mb-2" title="Line No_" placeholder="Line No_"></v-autocomplete>
          <v-textarea dense hide-details v-model="fmEdit.data.description" outlined class="mb-2" title="Description" placeholder="Description"></v-textarea>
          <v-text-field append-icon="mdi-upload" @click:append="uploadFile()" dense v-model="fmEdit.data.attachment" placeholder="Attachment" label="Attachment" outlined hide-details class="mb-2"></v-text-field>
          <v-text-field type="date" dense hide-details v-model="fmEdit.data.action_date" outlined class="mb-2" title="Tanggal" placeholder="Tanggal"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="updateData()">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="fmEdit.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <input type="file" style="display: none" name="fileupload" id="fileupload" ref="reffileupload" v-on:change="handleFileUpload()" />
  </div>
</template>

<script>
export default {
  props: ["cpar_header_id"],
  data() {
    return {
      title: "List Correction / Perbaikan",
      loading: false,
      line_nos: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      data_list_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "PIC", value: "username", sortable: false },
        { text: "Tanggal", value: "action_date", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      data_list: [],
      fmAdd: {
        loading: false,
        dialog: false,
        data: {
          cpar_header_id: null,
          line_no_: null,
          description: null,
          attachment: null,
          action_date: null,
        },
      },
      fmEdit: {
        loading: false,
        dialog: false,
        data: {
          id: null,
          cpar_header_id: null,
          line_no_: null,
          description: null,
          attachment: null,
          action_date: null,
        },
      },
    };
  },

  methods: {
    async getData(refresh = false) {
      this.loading = true;

      if (refresh == true) this.AxiosStorageRemove("GET", "cparcorrection/data");

      var config = {
        params: {
          cpar_header_id: this.cpar_header_id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("cparcorrection/data", config)
        .then((res) => {
          var r = res.data.data.results;

          this.data_list = r

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    add() {
      this.fmAdd.loading = false;
      this.fmAdd.dialog = true;
      this.fmAdd.data.cpar_header_id = this.cpar_header_id;
      this.fmAdd.data.line_no_ = null;
      this.fmAdd.data.description = null;
      this.fmAdd.data.attachment = null;
      this.fmAdd.data.action_date = null;
    },

    editRow(item) {
      this.fmEdit.loading = false;
      this.fmEdit.dialog = true;
      this.fmEdit.data.id = item.id;
      this.fmEdit.data.cpar_header_id = item.cpar_header_id;
      this.fmEdit.data.line_no_ = parseInt(item.line_no_);
      this.fmEdit.data.description = item.description;
      this.fmEdit.data.attachment = item.attachment;
      this.fmEdit.data.action_date = item.action_date;
    },

    async saveData() {
      this.fmAdd.dialog = false;
      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("cpar_header_id", this.fmAdd.data.cpar_header_id);
      formData.append("line_no_", this.fmAdd.data.line_no_);
      formData.append("description", this.fmAdd.data.description);
      formData.append("action_date", this.fmAdd.data.action_date);
      formData.append("attachment", this.fmAdd.data.attachment);

      await this.$axios
        .post("cparcorrection/save", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
          }

          this.getData(true);
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async updateData() {
      this.fmEdit.dialog = false;
      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.fmEdit.data.id);
      formData.append("cpar_header_id", this.fmEdit.data.cpar_header_id);
      formData.append("line_no_", this.fmEdit.data.line_no_);
      formData.append("description", this.fmEdit.data.description);
      formData.append("action_date", this.fmEdit.data.action_date);
      formData.append("attachment", this.fmEdit.data.attachment);

      await this.$axios
        .post("cparcorrection/update", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
          }

          this.getData(true);
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    uploadFile() {
      this.$refs.reffileupload.click();
    },

    handleFileUpload() {
      this.fileupload = this.$refs.reffileupload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("fileupload", this.fileupload);
      this.$axios
        .post("cpar/upload-attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.fmAdd.data.attachment = resData.data.file_src;
            this.fmEdit.data.attachment = resData.data.file_src;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    deleteRow(id) {
      let post = async () => {
        var config = {
          params: {
            id: parseInt(id),
          },
          cacheConfig: false,
        };
        this.loading_reopen = true;
        this.showLoadingOverlay(true);

        await this.$axios
          .delete("cparcorrection/delete", config)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.getData(true);
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Delete Row?", post);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>


<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  vertical-align: top; 
  padding-top: 5px;
}
</style>